import { useApolloClient } from '@apollo/client';
import Button from '@aurora/shared-client/components/common/Button/Button';
import {
  ButtonVariant,
  LoadingButtonVariant
} from '@aurora/shared-client/components/common/Button/enums';
import {
  ToastAlertVariant,
  ToastVariant
} from '@aurora/shared-client/components/common/ToastAlert/enums';
import type ToastProps from '@aurora/shared-client/components/common/ToastAlert/ToastAlertProps';
import useFrameEnd, {
  getParentFrameId
} from '@aurora/shared-client/components/context/AnalyticsParentFrames/useFrameEnd';
import useToasts from '@aurora/shared-client/components/context/ToastContext/useToasts';
import useMutationWithTracing from '@aurora/shared-client/components/useMutationWithTracing';
import { hasErrors } from '@aurora/shared-client/helpers/apollo/ApolloHelper';
import type { TopicMessage } from '@aurora/shared-generated/types/graphql-schema-types';
import {
  AbuseModerationMessageAction,
  VisibilityScope
} from '@aurora/shared-generated/types/graphql-schema-types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import dynamic from 'next/dynamic';
import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useUIDSeed } from 'react-uid';
import type {
  ModerateAbuseMessageMutation,
  ModerateAbuseMessageMutationVariables
} from '../../../types/graphql-types';
import useTranslation from '../../useTranslation';
import confirmAbuseMutation from '../ModerateAbuseMessage.mutation.graphql';
import type { MessageActionType } from '../types';

const ConfirmationDialog = dynamic(
  () => import('@aurora/shared-client/components/common/ConfirmationDialog/ConfirmationDialog'),
  { ssr: false }
);

interface Props extends MessageActionType {
  /**
   * Whether the component is rendered from the message subject modal.
   */
  isModalAction?: boolean;
  /**
   * Callback function for toggling the display of subject overview modal.
   */
  onCloseSubjectModal?: () => void;
  /**
   * Callback function for hiding the subject overview modal.
   */
  onHideSubjectModal?: (isHideModal: boolean) => void;
  /**
   * Variant of the button.
   */
  variant?: ButtonVariant;
}

/**
 * Drop-down item which renders confirm abuse action button. On click of this button, a potential abuse message from the
 * content management dashboard list is  hidden from the Abuse Management View.
 *
 * @author Amit Agrawal
 */
const MessageActionConfirmAbuse: React.FC<React.PropsWithChildren<Props>> = ({
  message,
  isModalAction = false,
  onCloseSubjectModal,
  onHideSubjectModal,
  variant = ButtonVariant.PRIMARY
}) => {
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.MESSAGE_ACTION_CONFIRM_ABUSE
  );
  const client = useApolloClient();
  const { addToast } = useToasts();
  const uidSeed = useUIDSeed();
  const [showModal, setShowModal] = useState<boolean>(false);
  const isArchived = (message as TopicMessage).visibilityScope === VisibilityScope.Archived;

  const [confirmAbuse] = useMutationWithTracing<
    ModerateAbuseMessageMutation,
    ModerateAbuseMessageMutationVariables
  >(module, confirmAbuseMutation);

  const [frameEnd] = useFrameEnd();

  if (textLoading) {
    return null;
  }

  /**
   * Renders toast banner or flyout
   *
   * @param alertVariant toast alert variant.
   * @param key
   * @param titleKey toast title.
   * @param messageKey toast message.
   */
  function renderToast(
    alertVariant: ToastAlertVariant,
    titleKey: string,
    messageKey: string
  ): void {
    const id = uidSeed(`confirm-abuse-${titleKey}`);
    const toastVariant =
      alertVariant === ToastAlertVariant.DANGER ? ToastVariant.BANNER : ToastVariant.FLYOUT;
    const toastProps: ToastProps = {
      id,
      toastVariant,
      alertVariant,
      title: formatMessage(`confirmAbuse.${titleKey}`),
      autohide: true,
      message: formatMessage(`confirmAbuse.${messageKey}`),
      delay: 4000
    };
    addToast(toastProps);
  }

  /**
   * Action handler for confirm abuse action
   */
  async function handleConfirmAbuseAction(): Promise<void> {
    const mutationPayload: ModerateAbuseMessageMutationVariables = {
      entityId: message.id,
      action: AbuseModerationMessageAction.Reject
    };

    const {
      data: {
        moderateAbuseMessage: { errors: knownErrors }
      },
      errors
    } = await confirmAbuse({
      variables: mutationPayload,
      onCompleted: (d): void => {
        if (!hasErrors(d.moderateAbuseMessage)) {
          const parentFrameId = getParentFrameId();
          frameEnd({
            context: { parentFrameId }
          });
        }
      }
    });

    if (errors?.length > 0 || (knownErrors && knownErrors.length > 0)) {
      if (knownErrors[0].__typename === 'PermissionDeniedError') {
        renderToast(
          ToastAlertVariant.DANGER,
          'permissionDenied.errorTitle',
          'permissionDenied.errorMessage'
        );
      } else if (knownErrors[0].__typename === 'EntityAlreadyRejectedAsAbuseError') {
        renderToast(
          ToastAlertVariant.DANGER,
          'alreadyModerated.errorTitle',
          'alreadyModerated.errorMessage'
        );
      } else {
        renderToast(ToastAlertVariant.DANGER, 'errorTitle', 'errorMessage');
      }
      return;
    }
    if (isModalAction) {
      setTimeout(() => onHideSubjectModal(false), 500);
      onCloseSubjectModal();
    }
    client.cache.evict({ id: client.cache.identify(message) });
    client.cache.gc();
    renderToast(ToastAlertVariant.SUCCESS, 'successTitle', 'successMessage');
    setShowModal(false);
  }

  /**
   * Handles hide or cancel dialog action
   */
  function onHideOrCancelDialog() {
    if (isModalAction) {
      setTimeout(() => onHideSubjectModal(false), 500);
      onCloseSubjectModal();
    }
    setShowModal(false);
  }

  /**
   * Renders the confirmation dialog for edit draft
   */
  function renderConfirmAbuseDialog(): React.ReactElement {
    return (
      <ConfirmationDialog
        show={showModal}
        onHide={() => onHideOrCancelDialog()}
        onSubmit={() => handleConfirmAbuseAction()}
        onCancel={() => onHideOrCancelDialog()}
        titleText={formatMessage('modal.title')}
        bodyText={formatMessage('modal.body')}
        submitButtonType={LoadingButtonVariant.LOADING_BUTTON}
        submitButtonText={formatMessage('modal.submit')}
      />
    );
  }

  return (
    !isArchived && (
      <>
        {isModalAction ? (
          <Button
            data-testid="MessageActionConfirmAbuseButton"
            size="lg"
            variant={variant}
            onClick={() => {
              onHideSubjectModal(true);
              setShowModal(true);
            }}
          >
            {formatMessage('menuItem.confirmAbuse')}
          </Button>
        ) : (
          <Dropdown.Item
            as="button"
            type="button"
            onClick={() => setShowModal(true)}
            data-testid="MessageActionConfirmAbuse"
          >
            {formatMessage('menuItem.confirmAbuse')}
          </Dropdown.Item>
        )}
        {showModal && renderConfirmAbuseDialog()}
      </>
    )
  );
};

export default MessageActionConfirmAbuse;
